import './editor.scss';
import './style.scss';

import Flickity from 'react-flickity-component';

const { __ } = wp.i18n;
const { InspectorControls, InnerBlocks } = wp.blockEditor;
const { registerBlockType } = wp.blocks;
const { withSelect } = wp.data;
const {
	PanelBody,
	RangeControl,
	TextControl,
	ToggleControl,
	SelectControl,
} = wp.components;
const { decodeEntities } = wp.htmlEntities;
const { createElement, RawHTML, Fragment } = wp.element;

registerBlockType('pixelbound/services-carousel', {
	title: __('Services Carousel', 'pixelbound_services'),
	icon: 'slides',
	keywords: [
		__('Block', 'pixelbound_services'),
		__('Services', 'pixelbound_services'),
		__('Carousel', 'pixelbound_services'),
	],
	attributes: {
		imageAspectRatio: {
			type: 'string',
			default: '1:1',
		},
		numberOfServices: {
			type: 'number',
			default: 6,
		},
		numberOfServicesToShow: {
			type: 'number',
			default: 3,
		},
		serviceCategories: {
			type: 'boolean',
			default: true,
		},
		serviceExcerpt: {
			type: 'boolean',
			default: true,
		},
		serviceLink: {
			type: 'boolean',
			default: true,
		},
		serviceLinkLabel: {
			type: 'string',
			default: __('Find Out More', 'pixelbound-services'),
		},
		numberOfServices: {
			type: 'number',
			default: 6,
		},
		spacingTop: {
			type: 'number',
			default: 4,
		},
		spacingBottom: {
			type: 'number',
			default: 4,
		},
	},
	edit: withSelect((select, props) => {
		const { attributes } = props;
		const { numberOfServices } = attributes;

		// Get the current post ID so it can be excluded from the carousel
		const postID = wp.data.select('core/editor').getCurrentPostId();

		const services = select('core').getEntityRecords('postType', 'pb_service', {
			per_page: numberOfServices,
			exclude: postID,
			orderby: 'date',
			order: 'desc',
		});

		const servicesToReturn =
			services &&
			services.map((service) => {
				let categoriesInfo = [];
				let featuredMediaDetails = null;

				// If the post has a featured image then let's get it and add it to out post object
				if (service.featured_media) {
					featuredMediaDetails = select('core').getMedia(
						service.featured_media
					);
				}

				// If the post is assigned to any categories then let's get them and add them to the post object
				if (service.categories) {
					const categories = select('core').getEntityRecords(
						'taxonomy',
						'category',
						{
							per_page: -1,
						}
					);

					if (categories) {
						service.categories.forEach((categoryID) => {
							categories.forEach((category) => {
								if (category.id === categoryID) {
									categoriesInfo.push({
										id: category.id,
										name: category.name,
										url: category.link,
									});
								}
							});
						});
					}
				}

				return {
					...service,
					featuredMediaDetails,
					categoriesInfo,
				};
			});

		return {
			services: servicesToReturn,
		};
	})((props) => {
		const { services, className, attributes, setAttributes } = props;
		const {
			imageAspectRatio,
			serviceCategories,
			serviceExcerpt,
			serviceLink,
			serviceLinkLabel,
			numberOfServices,
			numberOfServicesToShow,
			spacingTop,
			spacingBottom,
		} = attributes;

		// Construct inline styles object
		let inlineStyles = {};
		spacingTop ? (inlineStyles.marginTop = `${spacingTop}em`) : '0em';
		spacingBottom ? (inlineStyles.marginBottom = `${spacingBottom}em`) : '0em';

		return (
			<Fragment>
				<InspectorControls>
					<PanelBody
						title={__('Settings', 'pixelbound-services')}
						initialOpen={true}
					>
						<SelectControl
							label={__('Image Aspect Ratio', 'pixelbound')}
							options={[
								{
									label: __('Ratio 1:1', 'pixelbound-services'),
									value: '1:1',
								},
								{
									label: __('Ratio 4:3', 'pixelbound-services'),
									value: '4:3',
								},
								{
									label: __('Ratio 5:4', 'pixelbound-services'),
									value: '5:4',
								},
								{
									label: __('Ratio 16:9', 'pixelbound-services'),
									value: '16:9',
								},
							]}
							value={imageAspectRatio}
							onChange={(value) => setAttributes({ imageAspectRatio: value })}
						/>
						<ToggleControl
							label={__('Toggle Post Categories', 'pixelbound-services')}
							help={
								serviceCategories
									? __('Disable the post categories', 'pixelbound-services')
									: __('Enable the post categories', 'pixelbound-services')
							}
							checked={serviceCategories}
							onChange={(serviceCategories) =>
								setAttributes({ serviceCategories })
							}
						/>
						<ToggleControl
							label={__('Toggle Post Excerpt', 'pixelbound-services')}
							help={
								serviceExcerpt
									? __('Disable the post excerpt', 'pixelbound-services')
									: __('Enable the post excerpt', 'pixelbound-services')
							}
							checked={serviceExcerpt}
							onChange={(serviceExcerpt) => setAttributes({ serviceExcerpt })}
						/>
						<ToggleControl
							label={__('Toggle Post Link', 'pixelbound-services')}
							help={
								serviceLink
									? __('Disable the post link', 'pixelbound-services')
									: __('Enable the post link', 'pixelbound-services')
							}
							checked={serviceLink}
							onChange={(serviceLink) => setAttributes({ serviceLink })}
						/>
						<TextControl
							label={__('Post Button Label', 'pixelbound-services')}
							help={__(
								'Change the text that appears in the "Read More" buttons that appear in this carousel.',
								'pixelbound-services'
							)}
							value={
								serviceLinkLabel
									? serviceLinkLabel
									: __('Read More', 'pixelbound-services')
							}
							onChange={(serviceLinkLabel) =>
								setAttributes({ serviceLinkLabel })
							}
						/>
						<hr />
						<RangeControl
							label={__('Number Of Posts', 'pixelbound-services')}
							help={__(
								'How many posts should be included in the carousel.',
								'pixelbound-services'
							)}
							value={numberOfServices}
							onChange={(value) => setAttributes({ numberOfServices: value })}
							step={1}
							min={1}
							max={12}
						/>
						<RangeControl
							label={__('Number Of Posts to Show', 'pixelbound-services')}
							help={__(
								'How many posts should be visible per slide.',
								'pixelbound-services'
							)}
							value={numberOfServicesToShow}
							onChange={(value) =>
								setAttributes({ numberOfServicesToShow: value })
							}
							step={1}
							min={1}
							max={4}
						/>
						<RangeControl
							label={__('Spacing Top', 'pixelbound-services')}
							value={spacingTop}
							onChange={(value) => setAttributes({ spacingTop: value })}
							step={0.1}
							min={0}
							max={20}
						/>
						<RangeControl
							label={__('Spacing Bottom', 'pixelbound-services')}
							value={spacingBottom}
							onChange={(value) => setAttributes({ spacingBottom: value })}
							step={0.1}
							min={0}
							max={20}
						/>
					</PanelBody>
				</InspectorControls>

				{services && services.length > 0 ? (
					<div className={className} style={inlineStyles}>
						<Flickity
							className={`wp-block-pixelbound-services-carousel show-${numberOfServicesToShow}-services`}
							elementType={'div'}
							options={{
								groupCells: true,
								cellAlign: 'left',
								contain: true,
								pageDots: false,
							}}
						>
							{services.map((service) => (
								<article
									className={'wp-block-pixelbound-service'}
									key={service.id}
								>
									<div className={'wp-block-pixelbound-service__inner'}>
										{service.featuredMediaDetails && (
											<div
												className={`wp-block-pixelbound-service__image ratio-${imageAspectRatio}`}
											>
												<a
													href={service.link}
													target="_blank"
													rel="noopener noreferrer"
													style={{
														backgroundImage: `url( ${service.featuredMediaDetails.source_url})`,
													}}
												></a>
											</div>
										)}

										<h2 className={'wp-block-pixelbound-service__title'}>
											<a
												href={service.link}
												target="_blank"
												rel="noopener noreferrer"
											>
												{decodeEntities(service.title.rendered)}
											</a>
										</h2>
										{serviceCategories && service.categoriesInfo && (
											<div className={'wp-block-pixelbound-service__meta'}>
												<ul
													className={'wp-block-pixelbound-service__categories'}
												>
													{service.categoriesInfo.map((category) => (
														<li>
															<a
																href={category.url}
																target="_blank"
																rel="noopener noreferrer"
															>
																{category.name}
															</a>
														</li>
													))}
												</ul>
											</div>
										)}
										{serviceExcerpt && service.excerpt && (
											<div className={'wp-block-pixelbound-service__excerpt'}>
												{service.excerpt.raw}
											</div>
										)}
										{serviceLink && service.link && (
											<a
												className={'wp-block-pixelbound-service__link'}
												href={service.link}
												target="_blank"
												rel="noopener noreferrer"
											>
												{serviceLinkLabel
													? serviceLinkLabel
													: __('Find Out More', 'pixelbound-services')}
											</a>
										)}
									</div>
								</article>
							))}
						</Flickity>
					</div>
				) : (
					<div>
						{services
							? __('No services to display.', 'pixelbound_services')
							: __('Loading...', 'pixelbound_services')}
					</div>
				)}
			</Fragment>
		);
	}),
	save: () => {
		return '';
	},
});
